import Register from '../../../utils/Register';

export default class ProductListItem {

	constructor ($item) {
		const _this = this;

		$item
			.on('mouseenter', () => this.showBackPhoto($item))
			.on('mouseleave', () => this.showFrontPhoto($item))
	}

	showBackPhoto ($item) {
		if ($item.has('source[data-photo-change]')) {
			const $source = $item.find('source[data-photo-change]');
			$source
				.data('original-photo', $source.attr('srcset'))
				.attr('srcset', $source.data('photo-change'));
		}

		if ($item.has('img[data-photo-change]')) {
			const $img = $item.find('img[data-photo-change]');
			$img
				.data('original-photo', $img.attr('src'))
				.attr('src', $img.data('photo-change'));
		}
	}

	showFrontPhoto($item) {
		if ($item.has('source[data-photo-change]')) {
			const $source = $item.find('source[data-photo-change]');
			$source
				.attr('srcset', $source.data('original-photo'));

		}

		if ($item.has('img[data-photo-change]')) {
			const $img = $item.find('img[data-photo-change]');
			$img
				.attr('src', $img.data('original-photo'));
		}
	}

	static init ($container) {
		$container.find('.product-item').each(function(i, e) {
			new ProductListItem($(e));
		});
	}

}

(new Register()).registerCallback(ProductListItem.init, 'ProductListItem.init');